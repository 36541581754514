import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledListItem = styled.div`
  display: grid;
  grid-template-columns: 56px auto;
  text-align: left;
  margin-bottom: 16px;
`

const StyledNoticeIcon = styled.div`
  width: 56px;
  height: 56px;
  background: ${Colors.WHITE};
  border: 1px solid ${Colors.VERY_LIGHT_GRAY};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledDetail = styled.div`
  padding-left: 12px;
`

const StyledTitle = styled.div`
  min-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${Colors.BLACK};
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
`

const StyledDiff = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.GRAY};
  margin-left: 8px;
`

const StyledDescription = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  color: ${Colors.BLACK};
  font-weight: 500;
  font-size: 11px;
  line-height: 160%;
  white-space: pre-wrap;
`
const StyledCreatorCoverImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`

export {
  StyledListItem,
  StyledNoticeIcon,
  StyledDetail,
  StyledTitle,
  StyledDiff,
  StyledDescription,
  StyledCreatorCoverImg,
}
